<template>
  <v-card
    class="auto-carrier-card"
    elevation="2"
    rounded="lg"
  >
    <!-- Card Header with Delete Dialog -->
    <v-card-title class="card-title">
      <v-row>
        <v-col class="grow">
      
          <v-alert
            dense
            color="info"
            outlined
            class="mb-0"
            border="left"
          >
            <div class="d-flex align-center">
              <v-icon color="info" class="mr-2">mdi-information</v-icon>
              <span>Remember to add {{ $appName }} as a Carrier App Rate on Shopify (>Settings>Shipping&Delivery) after activating rates on all shipping zones you wish to use it.</span>
            </div>
          </v-alert>
        </v-col>
        <v-col class="shrink">
          <v-dialog
            v-model="delete_carrier_confirm"
            max-width="450"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                icon 
                outlined 
                color="error" 
                v-bind="attrs" 
                v-on="on"
                class="delete-btn"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <v-card rounded="lg" elevation="3">
              <v-card-title class="primary white--text">
                <v-icon left color="white">mdi-alert-circle</v-icon>
                Confirm Uninstall
              </v-card-title>
              <v-card-text class="pt-4">
                <p>This will remove Carrier App Rates from <strong>{{ store.name }}</strong> including all shipping methods where it is currently in use.</p>
              </v-card-text>
              <v-card-actions class="pb-4 px-4">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  rounded
                  @click="delete_carrier_confirm = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="error"
                  rounded
                  elevation="1"
                  @click="delete_carrier"
                  class="ml-2"
                >
                  <v-icon left small>mdi-delete</v-icon>
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-title>



    <v-divider class="mx-4"></v-divider>

    <!-- Card Content -->
    <v-card-text class="content-section">
      <!-- Loading State -->
      <template v-if="loading">
        <div class="text-center py-6">
          <v-progress-circular
            indeterminate
            color="primary"
            size="42"
            width="4"
            class="mb-3"
          ></v-progress-circular>
          <div class="text-body-2 grey--text text--darken-1">Loading carrier services...</div>
        </div>
      </template>

      <!-- Content -->
      <template v-else>
        <!-- Plan Upgrade Alert -->
        <v-alert
          v-if="!get_plan || !get_plan.allow_auto_carrier"
          type="info"
          outlined
          class="plan-alert mb-4"
        >
          <v-row align="center" no-gutters>
            <v-col class="grow">
              <div class="d-flex align-center">
                <v-icon color="primary" class="mr-2">mdi-shield-star</v-icon>
                <span>Upgrade your plan to use Carrier App Rates</span>
              </div>
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click="$router.push('/auth/plans')"
                color="primary"
                rounded
                elevation="1"
                class="ml-2"
              >
                <v-icon left small>mdi-arrow-right</v-icon>
                View Plans
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>

        <!-- Service Components -->
        <div class="service-components">
          <AutoCarrierServiceComponent 
            v-for="(service, key) in service_charges"
            :carrier_service="carrier_service"
            :key="`service-${key}`"
            :service="service"
            :loading="loading_card === service.id"
            :store="store"
            @save="new_service => save_service(new_service, service.id)"
            @deactivate="deactivate(service.id)"
            class="mb-4"
          />
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AutoCarrierServiceComponent from '@/components/AutoCarrierServiceComponent'

export default {
  components: {
    AutoCarrierServiceComponent
  },
  props: {
    store: {
      type: Object,
      required: true,
      default: null
    },
    carrier_service: {
      type: Object,
      required: true,
      default: null
    },
  },
  computed: {
    ...mapState({
      company: s => s.warehouse.company,
      shipping: state => state.shipping,
      service_charges: function(s) { 
        // Create a new array instead of sorting the original to avoid infinite update loop
        return [...s.shipping.service_charges].sort((a, b) => a.name.localeCompare(b.name))
      },
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
    }),
    // available_service_charges: function() { 
    //   return this.service_charges?.filter(s => !this.carrier_service.active_services.some(acs => acs.sf_id === s.id)) 
    // },
  },
  data() {
    return {
      loading: true,
      adding_new: false,
      loading_card: '',
      delete_carrier_confirm: false,
    }
  },
  methods: {
    delete_carrier() {
      this.loading = true
      this.delete_carrier_confirm = false
      this.$emit('delete')
    },
    async save_service(new_service){
      console.log('at save_service', new_service)
      this.loading_card = new_service.sf_id
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        auto_carrier_services: {
          ...this.store.auto_carrier_services,
          active_services: this.store.auto_carrier_services.active_services
            .filter(s => s.sf_id !== new_service.sf_id)
            .concat([ new_service ])
        }
      })
      this.loading_card = ''
    },
    async deactivate(sf_id){
      const { ok } = await this.$prompt({
        text: 'Are you sure?',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      })
      if(!ok) return 
      this.loading_card = sf_id
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        auto_carrier_services: {
          ...this.store.auto_carrier_services,
          active_services: this.store.auto_carrier_services.active_services
            .filter(s => s.sf_id !== sf_id)
        }
      })
      this.loading_card = ''
    }
  },
  async mounted(){
    console.log('carrier_service', JSON.parse(JSON.stringify(this.carrier_service)))
    if(!this.service_charges?.length) await this.$store.dispatch('shipping/get_service_charges')
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.auto-carrier-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  
  &:hover {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
  
  .card-title {
    padding-bottom: 12px;
    
    @media (max-width: 600px) {
      padding: 12px 8px;
    }
  }
  
  .info-subtitle {
    padding-top: 0;
    padding-bottom: 16px;
  }
  
  .content-section {
    background-color: #f9f9fa;
    padding-top: 20px;
    
    @media (max-width: 600px) {
      padding: 12px 8px;
    }
  }
  
  .plan-alert {
    border-left-width: 4px !important;
    animation: fadeIn 0.6s ease-out;
  }
  
  .service-components {
    animation: fadeIn 0.6s ease-out;
  }
  
  .delete-btn {
    transition: all 0.2s ease;
    
    &:hover {
      background-color: rgba(var(--v-error-base-rgb), 0.1);
      transform: scale(1.1);
    }
  }
  
  /* Mobile-specific adjustments */
  @media (max-width: 600px) {
    .v-card__title {
      padding: 12px 8px;
    }
    
    .v-card__text {
      padding: 12px 8px;
    }
    
    .v-alert {
      padding: 8px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
