<template>  
  <v-card
    outlined
    rounded="lg"
    elevation="3"
    class="store-card ma-4"
  >
    <v-card-title class="card-title primary--text font-weight-medium">
      <v-row>
        <v-col cols="auto" md="12" sm="12" lg="auto" class="grow">
          <v-icon left color="primary">mdi-store</v-icon>
          {{  store.name }}
        </v-col>
        <v-col cols="auto" md="12" sm="12" lg="auto" class="shrink d-flex align-center">
          <v-dialog
            v-model="dialog"
            fullscreen
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                rounded
                elevation="2"
                class="px-6 shipping-btn mr-2"
                v-bind="attrs"
                v-on="on"
                v-if="is_fulfiller"
              >
                <v-icon left>mdi-truck-delivery-outline</v-icon>
                Shipping settings
              </v-btn>
            </template>
            <ShippingMapDialog 
              @close="dialog = false"
              :store="store"
            />
          </v-dialog>
          
          <v-btn
            icon
            @click="expanded = !expanded"
            class="expand-btn"
            :class="{ 'rotate-icon': expanded }"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    
    <v-expand-transition>
      <v-card-text v-show="expanded" class="pa-4">
      <v-row>
        <!-- Store Name -->
        <v-col cols="12" md="6">
          <v-text-field
            rounded
            outlined
            v-model="store.name"
            label="Store name"
            prepend-inner-icon="mdi-store-outline"
            :disabled="!is_owner"
            @change="update_data_source({name: store.name})"
            class="store-field"
          />
        </v-col>
        
        <!-- Store Options -->
        <v-col cols="12" md="6">
          <v-switch
            v-model="store.allow_partial_fulfillment"
            flat
            dense
            class="my-0 mb-2 switch-option"
            @change="update_data_source({allow_partial_fulfillment: store.allow_partial_fulfillment})"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <span>Allow order splitting</span>
                <ToolTip>If any item in an order is missing, allowing order splitting will create a partial fulfillment.</ToolTip>
              </div>
            </template>
          </v-switch>
          
          <v-switch
            v-model="store.allow_transfer"
            flat
            dense
            :disabled="!get_plan || !get_plan.allow_store_transfer"
            class="my-0 mb-2 switch-option"
            v-if="is_owner && is_fulfiller"
            @change="update_data_source({allow_transfer: store.allow_transfer})"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <span>Allow store transfer</span>
                <ToolTip>If you wish to fulfill this store under another account, open up your store for transfer to get your keys and use these to add the store on your other account.</ToolTip>
              </div>
            </template>
          </v-switch>
          
          <v-switch
            v-model="store.exclude_from_fulfillment"
            dense
            flat
            class="my-0 mb-2 switch-option"
            v-if="is_owner && is_fulfiller && get_plan && get_plan.id === 5"
            @change="update_data_source({ exclude_from_fulfillment: store.exclude_from_fulfillment })"
          >
            <template v-slot:label>
              <div class="d-flex align-center">
                <span>Exclude from Fulfillment</span>
                <ToolTip>If checked, this store will be excluded. Used for 3PL Partners where they do not want their own store to count toward their 3PL Connects.</ToolTip>
              </div>
            </template>
          </v-switch>
        </v-col>
        
        <!-- EORI Number -->
        <v-col cols="12" md="6">
          <v-text-field
            rounded
            outlined
            v-model="store.eori_number"
            label="EORI / customs number"
            prepend-inner-icon="mdi-identifier"
            @change="update_data_source({eori_number: store.eori_number})"
            class="store-field"
          />
        </v-col>
        
        <!-- Currency Code -->
        <v-col cols="12" md="2">
          <v-text-field
            rounded
            outlined
            v-model="store.currency_code"
            label="Currency code"
            prepend-inner-icon="mdi-currency-usd"
            @change="update_data_source({currency_code: store.currency_code})"
            class="store-field"
          />
        </v-col>
        <v-col cols="12" md="4" style="padding-top:0" rounded>
          <fieldset style="border-radius: 8px">
            <legend class="text-caption ml-2">
              Color 
              <ToolTip>
                Will color code the header on the packing card, for easier detection amongst warehouse staff.
              </ToolTip>
            </legend>
            <div class="d-flex align-center">
              <v-icon class="mr-2 ml-1">mdi-palette</v-icon>
              <v-color-picker
                dot-size="25"
                hide-canvas
                hide-inputs
                hide-sliders
                swatches-max-height="60px"
                :swatches="swatches"
                mode="hexa"
                v-model="localColor"
                show-swatches
                class="flex-grow-1"
              ></v-color-picker>
              <v-btn
                icon
                small
                class="ml-2"
                @click="resetColor"
                v-if="store.color_identifier"
                title="Reset color"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </fieldset>
        </v-col>
        <v-col cols="12" md="12">
          <v-card flat class="pa-4 mb-4" outlined>
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center">
                    <v-icon left color="primary">mdi-information-outline</v-icon>
                    <h3 class="text-h6 mb-0">Packing Instructions</h3>
                    <v-chip 
                      class="ml-2" 
                      x-small 
                      outlined
                      v-if="packingInstructions.length > 0"
                    >
                      {{ packingInstructions.length }}
                    </v-chip>
                  </div>
                </v-expansion-panel-header>
                
                <v-expansion-panel-content>
                  <div v-if="packingInstructions.length === 0" class="text-center pa-4 grey--text">
                    No packing instructions added yet
                  </div>
                  
                  <div v-else class="mb-4">
                    <v-card
                      v-for="(instruction, index) in packingInstructions"
                      :key="index"
                      outlined
                      class="mb-2 rounded-lg instruction-card"
                    >
                      <v-card-text class="py-2">
                        <div class="d-flex justify-space-between align-center">
                          <div>
                            <div class="font-weight-medium">{{ instruction.text }}</div>
                            <div v-if="instruction.condition" class="mt-1">
                              <v-chip x-small class="mr-1" color="primary" outlined>
                                <v-icon left x-small>mdi-tag</v-icon>
                                {{ instruction.condition.key }}
                              </v-chip>
                              <span class="text-caption">Only for parcels containing products with this tag</span>
                            </div>
                          </div>
                          <v-btn icon small @click="removeInstruction(index)" class="ml-2">
                            <v-icon small>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                  
                  <v-divider class="mb-3"></v-divider>
                  
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <div class="d-flex align-center">
                          <v-icon left small color="primary">mdi-plus</v-icon>
                          <span>Add new instruction</span>
                        </div>
                      </v-expansion-panel-header>
                      
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="newInstruction.text"
                              label="Instruction text"
                              outlined
                              dense
                              placeholder="Enter packing instruction"
                            ></v-text-field>
                          </v-col>
                          
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="conditionType"
                              :items="conditionOptions"
                              item-text="text"
                              item-value="value"
                              label="Condition (optional)"
                              outlined
                              dense
                              return-object
                            ></v-select>
                          </v-col>
                          
                          <v-col cols="12" v-if="conditionType && conditionType.value === 'parcel-contains-tag'">
                            <v-text-field
                              v-model="newInstruction.condition.key"
                              label="Product tag"
                              outlined
                              dense
                              placeholder="Enter tag"
                              :rules="[v => !!v || 'Tag is required when condition is selected']"
                            ></v-text-field>
                          </v-col>
                          
                          <v-col cols="12" class="d-flex justify-end">
                            <v-btn
                              color="primary"
                              @click="addInstruction"
                              :disabled="!canAddInstruction"
                              rounded
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Add instruction
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
        <!-- Store Transfer Information -->
        <template v-if="store.allow_transfer">
          <v-col cols="12" v-if="is_owner && is_fulfiller">
            <v-alert
              type="warning"
              border="left"
              colored-border
              elevation="2"
              class="transfer-warning mb-4"
              dense
            >
              <p class="mb-2">
                Anyone with your "Store Id" and "Store transfer key" will be able to take control of your fulfillment. Unless you intend on transferring this store to another {{ $appName }} account - leave "Allow Store Transfer" off. 
              </p>
              <p class="mb-0">
                If you connect your store to another account, you as the store owner will always be able to take back your store at your sole discretion.
              </p>
            </v-alert>
          </v-col>
          
          <v-col cols="12" md="6" v-if="is_owner && is_fulfiller">
            <v-text-field
              rounded
              outlined
              type="text"
              v-model="store.id"
              append-outer-icon="mdi-content-copy"
              @click:append-outer="copy(store.id)"
              label="Store id"
              prepend-inner-icon="mdi-store-outline"
              readonly
              class="store-field"
            />
          </v-col>
          
          <v-col cols="12" md="6" v-if="is_owner && is_fulfiller">
            <v-text-field
              rounded
              outlined
              :type="!show_key ? 'password' : 'text'"
              v-model="store.transfer_key"
              append-outer-icon="mdi-content-copy"
              append-icon="mdi-eye"
              @click:append="show_key = !show_key"
              @click:append-outer="copy(store.transfer_key)"
              label="Store transfer key"
              prepend-inner-icon="mdi-key"
              readonly
              class="store-field"
            />
          </v-col>
        </template>
        
        <!-- Store Uninstalled Warning -->
        <v-col cols="12" v-if="!store.subscription_plan">
          <v-alert
            type="error"
            border="left"
            colored-border
            elevation="2"
            class="mb-4"
            dense
          >
            This store owner has uninstalled {{ $appName }}. This store is no longer accessible.
          </v-alert>
        </v-col>
        
        <!-- Store Being Fulfilled Info -->
        <v-col cols="12" v-if="is_owner && !is_fulfiller">
          <v-alert
            type="info"
            border="left"
            colored-border
            elevation="2"
            class="mb-4"
            dense
          >
            <v-row align="center">
              <v-col class="grow">
                Your store is being fulfilled by {{ store.fulfilling_company_name }}
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="primary"
                  rounded
                  elevation="2"
                  @click="revoke_transfer"
                  class="action-btn"
                >
                  <v-icon left>mdi-undo</v-icon>
                  Revoke
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        
        <!-- Shared Store Info -->
        <v-col cols="12" v-if="!is_owner && is_fulfiller">
          <v-alert
            type="info"
            border="left"
            colored-border
            elevation="2"
            class="mb-4"
            dense
          >
            <v-row align="center">
              <v-col class="grow">
                This is a store shared with you, owned by someone else
              </v-col>
              <v-col class="shrink">
                <v-btn
                  color="error"
                  rounded
                  elevation="2"
                  @click="remove_store"
                  class="action-btn"
                >
                  <v-icon left>mdi-delete</v-icon>
                  Remove store
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ToolTip from '@/components/ToolTip.vue'
import ShippingMapDialog from '@/components/ShippingMapDialog'

export default {
  props: {
    store: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
      type: Number,
    }
  },
  components: {
    ToolTip,
    ShippingMapDialog,
  },
  data(){
    return {
      show_key: false,
      dialog: false,
      expanded: false,
      localColor: null,
      packingInstructions: [],
      newInstruction: {
        text: '',
        condition: null
      },
      conditionType: null,
      conditionOptions: [
        { text: 'No condition', value: null },
        { text: 'Parcel contains a product with tag', value: 'parcel-contains-tag' }
      ],
      swatches: [
        [
          '#E3F2FDFF', // Light Blue with alpha
          '#E8F5E9FF', // Light Green with alpha
          '#FFF3E0FF', // Light Orange with alpha
        ],
        [
          '#F3E5F5FF', // Light Purple with alpha
          '#FFEBEEFF', // Light Red with alpha
          '#E0F7FAFF', // Light Cyan with alpha
        ],
        [
          '#FFF8E1FF', // Light Amber with alpha
          '#F1F8E9FF', // Light Light Green with alpha
          '#E8EAF6FF', 
        ]
        // '#FCE4ECFF'  // Light Pink with alpha
      ],
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      is_fulfiller: 'app/is_fulfiller'
    }),
    is_owner(){
      return this.store.parent_company_id === this.company.id
    },
    is_fulfiller(){
      return this.store.fulfilling_company_id === this.company.id
    },
    canAddInstruction() {
      if (!this.newInstruction.text) return false;
      
      // If condition type is selected, make sure key is provided
      if (this.conditionType && this.conditionType.value === 'parcel-contains-tag') {
        return !!this.newInstruction.condition.key;
      }
      
      return true;
    }
  },
  watch: {
    localColor(newVal) {
      // Only update if the color is valid and not the default red
      if (newVal && (typeof newVal === 'string' || newVal.hexa !== '#FF0000FF')) {
        const colorValue = typeof newVal === 'string' ? newVal : newVal.hexa;
        this.update_data_source({ color_identifier: colorValue });
      }
    },
    'store.packing_instructions': {
      immediate: true,
      handler(val) {
        if (val) {
          try {
            // Try to parse as JSON if it's a string
            if (typeof val === 'string') {
              const parsed = JSON.parse(val);
              if (Array.isArray(parsed)) {
                this.packingInstructions = parsed;
                return;
              }
              // If it's a string but not a valid array JSON, convert it to our new format
              if (val.trim()) {
                this.packingInstructions = [{ text: val, condition: null }];
              } else {
                this.packingInstructions = [];
              }
            } else if (Array.isArray(val)) {
              // It's already an array
              this.packingInstructions = val;
            } else {
              this.packingInstructions = [];
            }
          } catch (e) {
            // If parsing fails, it's an old string format
            if (val && typeof val === 'string' && val.trim()) {
              this.packingInstructions = [{ text: val, condition: null }];
            } else {
              this.packingInstructions = [];
            }
          }
        } else {
          this.packingInstructions = [];
        }
      }
    },
    conditionType(val) {
      if (!val || val.value === null) {
        this.newInstruction.condition = null;
      } else if (val.value === 'parcel-contains-tag') {
        this.newInstruction.condition = {
          type: 'parcel-contains-tag',
          key: ''
        };
      }
    }
  },
  methods: {
    copy(text){
      navigator.clipboard.writeText(text);
      this.$store.commit('app/SET_SNACK_BAR', {
        text: 'Copied to clipboard',
        type: 'success'
      });
    },
    addInstruction() {
      if (!this.newInstruction.text) return;
      
      const instruction = {
        text: this.newInstruction.text,
        condition: this.newInstruction.condition
      };
      
      this.packingInstructions.push(instruction);
      this.savePackingInstructions();
      
      // Reset form
      this.newInstruction = {
        text: '',
        condition: null
      };
      this.conditionType = null;
    },
    removeInstruction(index) {
      this.packingInstructions.splice(index, 1);
      this.savePackingInstructions();
    },
    savePackingInstructions() {
      this.update_data_source({
        packing_instructions: this.packingInstructions
      });
    },
    update_data_source(set){
      console.log(set);
      if('exclude_from_fulfillment' in set && !set.exclude_from_fulfillment && this.get_plan.amount_logistics_connects < this.company.data_sources.length) {
        setTimeout(() => this.$store.commit('warehouse/SET_DATA_SOURCE', { id: this.store.id, exclude_from_fulfillment: true }), 100);
        return this.$store.commit('app/SET_SNACK_BAR', {
          text: 'You have too few available 3PL Connects',
          type: 'error'
        });
      }
      this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        ...set
      });
    },
    async remove_store(){
      const { ok } = await this.$prompt({
        text: 'Are you sure? This action can not be undone.',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      });
      if(!ok) return;
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        fulfilling_company_id: this.store.parent_company_id,
        fulfilling_company_name: '',
      });

      if(this.store.auto_carrier_services?.active) await this.$store.dispatch('warehouse/delete_auto_carrier', { 
          ds_id: this.store.id,
        });
    },
    handleColorChange(color) {
      // Only update if the color is valid and not the default red that appears on initialization
      if (color && (typeof color === 'string' || color.hexa !== '#FF0000FF')) {
        const colorValue = typeof color === 'string' ? color : color.hexa;
        this.update_data_source({ color_identifier: colorValue });
      }
    },
    resetColor() {
      // Set localColor to null first to avoid triggering the watch with the default red
      this.localColor = null;
      
      // Update the data source
      this.update_data_source({ color_identifier: '' });
      
      // Set the store value to empty string
      this.store.color_identifier = '';
    },
    async revoke_transfer(){
      const { ok } = await this.$prompt({
        text: 'Are you sure? This action can not be undone.',
        buttons: [
          {
            value: { ok: true },
            text: 'Proceed'
          },
          {
            value: { ok: false },
            text: 'Cancel'
          },
        ]
      });
      if(!ok) return;
      if(this.store.auto_carrier_services?.active) await this.$store.dispatch('warehouse/delete_auto_carrier', { 
          ds_id: this.store.id,
        });
      const old_company_id = this.store.fulfilling_company_id;
      const save_company = this.$functions.httpsCallable('save_company');
      await Promise.all([
        this.$store.dispatch('warehouse/save_data_source', {
          id: this.store.id,
          fulfilling_company_id: this.company.id,
          fulfilling_company_name: this.company.name,
        }),
        save_company( { company_id: old_company_id, remove_data_source_id: this.store.id })
      ]);
    }
  },
  mounted(){
    if(this.$route.query.showDialog) this.dialog = true;
    else this.dialog = false;
    
    // Initialize color_identifier to prevent default red color from being saved
    if (this.store.color_identifier === undefined) {
      this.store.color_identifier = '';
    }
    
    // Initialize localColor with the store's color_identifier
    this.localColor = this.store.color_identifier || null;
  }
}
</script>

<style lang="scss" scoped>
.store-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  animation: fadeIn 0.6s ease-out;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  }
  
  .card-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }
}

.store-field {
  transition: all 0.3s ease;
  
  &:hover:not(.v-input--is-disabled) {
    transform: translateY(-2px);
  }
}

.switch-option {
  transition: opacity 0.3s ease;
  
  &:hover:not(.v-input--is-disabled) {
    opacity: 0.9;
  }
}

.transfer-warning {
  border-width: 4px !important;
}

.action-btn, 
.shipping-btn {
  transition: transform 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
  }
}

.expand-btn {
  transition: transform 0.3s ease;
}

.rotate-icon {
  transform: rotate(180deg);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
