<template>
  <v-card class="shipping-map-dialog">
    <!-- Modern Toolbar -->
    <v-toolbar
      dark
      color="primary"
      elevation="2"
      class="dialog-toolbar"
    >
      <v-btn
        icon
        dark
        @click="$emit('close')"
        class="mr-2"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="font-weight-medium">
        <span class="text-h6">Shipping settings: {{ store.name }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <!-- Content Area -->
    <div class="dialog-content">
      <template v-if="!loading">
        <!-- Warning Alert -->
        <v-alert
          v-if="disable_edit"
          class="ma-6 warning-alert"
          type="warning"
          outlined
          dense
        >
          {{ !get_plan || !get_plan.max_carriers ? 
            'Your current plan does not allow Shipping routing to be edited. Upgrade to a plan that allows carrier accounts. Your previously saved Shipping maps are accessible.' 
            :
            'You can not edit shipping routing on a store that has transfered the fulfillment' }}
        </v-alert>

        <!-- Expansion Panels -->
        <v-container class="pa-4" fluid>
          <v-expansion-panels 
            v-model="panels"
            class="expansion-panels"
          >
            <!-- Fulfillment Location Panel -->
            <v-expansion-panel class="panel">
              <v-expansion-panel-header class="panel-header">
                <div class="d-flex align-center">
                  <v-icon color="primary" class="mr-3">mdi-map-marker</v-icon>
                  <span class="text-subtitle-1 font-weight-medium">Fulfillment Location</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="panel-content">
                <FulfillmentLocationSelect 
                  :store="store"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Carrier App Rates Panel -->
            <v-expansion-panel class="panel">
              <v-expansion-panel-header class="panel-header">
                <div class="d-flex align-center">
                  <v-icon color="primary" class="mr-3">mdi-truck-delivery</v-icon>
                  <span class="text-subtitle-1 font-weight-medium">Carrier App Rates</span>
                </div>
                <router-link
                  class="ml-2 add-link"
                  :to="'carriers'"
                  v-if="!company.carriers.length"
                >
                  Add carrier accounts
                </router-link>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="panel-content">
                <template v-if="company.carriers.some(c => (c.type === 'dhl_parcel_de' || c.type === 'postnord' || c.type === 'fedex')) && !(store.auto_carrier_services || {}).id ">
                  <div class="d-flex align-center justify-start" style="gap: 1em">
                    <v-btn
                      color="primary"
                      rounded
                      elevation="1"
                      @click="connect_auto_carrier"
                      :loading="adding_new_loading"
                      class="px-4"
                    >
                      <v-icon left>mdi-check-circle</v-icon>
                      Activate on shop
                    </v-btn>
                  </div>
                </template>
                <v-alert 
                  type="info"
                  dense
                  outlined
                  class="info-alert"
                  v-else-if="!(store.auto_carrier_services || {}).id"
                >
                  <div class="d-flex justify-space-around align-center">
                    <span>You need to connect a carrier to Fulfillment Hero.</span>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      text
                      link
                      to="carriers"
                      rounded
                    >
                      <v-icon left small>mdi-link-variant</v-icon>
                      Integrate with Carriers
                    </v-btn>
                  </div>
                </v-alert>
                <AutoCarrierServiceCard
                  :store="store"
                  v-if="!!(store.auto_carrier_services || {}).id"
                  :carrier_service="store.auto_carrier_services"
                  @delete="delete_auto_carrier()"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Unmapped Shipping Methods Panel -->
            <v-expansion-panel class="panel">
              <v-expansion-panel-header class="panel-header">
                <div class="d-flex align-center">
                  <v-icon color="warning" class="mr-3">mdi-map-marker-off</v-icon>
                  <span class="text-subtitle-1 font-weight-medium">
                    Unmapped manual shipping methods ({{ get_unmapped_zones(store.id).length }})
                  </span>
                </div>
                <router-link
                  class="ml-2 add-link"
                  :to="'carriers'"
                  v-if="!company.carriers.length"
                >
                  Add carrier accounts
                </router-link>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="panel-content">
                <ShippingMapCard 
                  v-for="(type, index) in get_unmapped_zones(store.id)"
                  :key="`type-${index}`" 
                  :shipping_method="type"
                  :store="store"
                  @save="save_map"
                  :disabled="disable_edit"
                  class="mb-4"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Mapped Shipping Methods Panel -->
            <v-expansion-panel class="panel">
              <v-expansion-panel-header class="panel-header">
                <div class="d-flex align-center">
                  <v-icon color="success" class="mr-3">mdi-map-marker-check</v-icon>
                  <span class="text-subtitle-1 font-weight-medium">
                    Mapped manual shipping methods ({{ store.shipping_map.length }})
                  </span>
                </div>
                <router-link
                  class="ml-2 add-link"
                  :to="'carriers'"
                  v-if="!company.carriers.length"
                >
                  Add carrier accounts
                </router-link>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="panel-content">
                <ShippingMapCard 
                  v-for="(map, index) in store.shipping_map"
                  :key="`mapped-${index}`" 
                  :shipping_method="map"
                  :store="store"
                  @save="update_map({ index, map: $event})"
                  @remove="remove_map(index)"
                  :disabled="disable_edit"
                  class="mb-4"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </template>

      <!-- Loading State -->
      <template v-else>
        <div class="loading-container">
          <v-progress-circular
            indeterminate
            :size="64"
            width="5"
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <div class="text-body-1 grey--text text--darken-1">Loading shipping settings...</div>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ShippingMapCard from '@/components/ShippingMapCard'
import FulfillmentLocationSelect from '@/components/FulfillmentLocationSelect'
import AutoCarrierServiceCard from '@/components/AutoCarrierServiceCard.vue'

export default {
  components: {
    ShippingMapCard,
    FulfillmentLocationSelect,
    AutoCarrierServiceCard,
  },
  props: {
    store: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      get_unmapped_zones: 'shipping/get_unmapped_zones'
    }),
    ...mapState({
      company: state => state.warehouse.company,
    }),
    disable_edit(){
      return !this.get_plan.max_carriers || (this.store.fulfilling_company_id !== this.company.id)
    },
  },
  data() {
    return {
      loading: true,
      adding_new: false,
      adding_new_loading: false,
      panels: 1,
    }
  },
  methods: {
    async delete_auto_carrier(){
      await this.$store.dispatch('warehouse/delete_auto_carrier', { 
        ds_id: this.store.id,
      });
    },
    async connect_auto_carrier(){
      const { ok } = await this.$prompt({
        text: 'By using Fulfillment Hero Carrier App Rates you acknowledge that Fulfillment Hero can not be held responsible for faulty quotes provided to customers no matter the reason. As Fulfillment Hero does not know the physical size of an order (only weight) shipping quotes presented to customers will not reflect over sized orders (although separate items can be tagged oversized at the responsibility of the user).',
        buttons: [
          { text: 'I approve', value: { ok: true } },
          { text: 'Cancel', value: { ok: false } },
        ]
      })
      console.log('ok', ok)
      if(!ok) return false
      this.adding_new_loading = true
      await this.$store.dispatch('warehouse/connect_auto_carrier', { 
          ds_id: this.store.id,
        })
      this.adding_new = false
      this.adding_new_loading = false
    },
    async remove_map(index){
      const shipping_map = this.store.shipping_map
      shipping_map.splice(index, 1)
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        shipping_map,
      })
      await this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id })
    },
    async update_map({ map, index }){
      console.log(index, map);
      const shipping_map = this.company.data_sources.filter(ds => ds.id === this.store.id)[0].shipping_map
      shipping_map[index] = map
      this.$store.commit('warehouse/UPDATE_SHIPPING_MAP', { map_index: index, map, store_id: this.store.id })
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        shipping_map
      })
      await this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id })
    },
    async save_map(req){
      console.log('save', req);
      const shipping_map = this.company.data_sources.filter(ds => ds.id === this.store.id)[0].shipping_map.concat([req])
      await this.$store.dispatch('warehouse/save_data_source', {
        id: this.store.id,
        shipping_map
      })
      await this.$store.dispatch('order/remap_shipping_in_order_buckets', { ds_id: this.store.id, company_id: this.company.id })
    }
  },
  async mounted(){
    console.log('mount');
    await this.$store.dispatch('shipping/get_shipping_zones', this.store.id)
    console.log('done');
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.shipping-map-dialog {
  border-radius: 12px;
  overflow: hidden;
  
  .dialog-toolbar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  
  .dialog-content {
    background-color: #f6f6f7;
    min-height: 300px;
  }
  
  .warning-alert {
    animation: fadeIn 0.6s ease-out;
    border-left-width: 4px !important;
  }
  
  .info-alert {
    animation: fadeIn 0.6s ease-out;
    border-left-width: 4px !important;
  }
  
  .expansion-panels {
    .panel {
      margin-bottom: 12px;
      border-radius: 8px;
      overflow: hidden;
      transition: box-shadow 0.3s ease, transform 0.3s ease;
      
      &:hover {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08) !important;
      }
      
      .panel-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding: 12px 16px;
      }

      ::v-deep.v-expansion-panel-content__wrap {
        @media (max-width: 600px) {
          padding: 0 0;
        }
      }
      
      ::v-deep.panel-content {
        padding: 16px;
        background-color: #ffffff;
        
        /* Reduce padding on mobile */
        @media (max-width: 600px) {
          padding: 8px;
        }
      }
    }
  }
  
  .add-link {
    color: var(--v-primary-base);
    font-size: 0.85rem;
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: var(--v-primary-darken1);
      text-decoration: underline;
    }
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 300px;
    padding: 32px;
    
    .v-progress-circular {
      animation: pulse 2s infinite ease-in-out;
    }
  }
  
  /* Mobile-specific container adjustments */
  @media (max-width: 600px) {
    .v-container.pa-4 {
      padding: 8px !important;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
