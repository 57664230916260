<template>
  <v-card
    class="shipping-map-card"
    :disabled="disabled"
    elevation="2"
    rounded="lg"
  >
    <v-form @submit.prevent="save_method" ref="methods_form">
      <!-- Card Header -->
      <v-row no-gutters>
        <v-col cols="10">
          <v-card-title v-if="shipping_method" class="card-title">
            <div class="d-flex align-center">
              <v-icon 
                :color="existing_method ? 'success' : 'warning'" 
                class="mr-3"
              >
                {{ existing_method ? 'mdi-map-marker-check' : 'mdi-map-marker-question' }}
              </v-icon>
              <div>
                {{ shipping_method.title }}
                <span v-if="shipping_method.sf_carrier_account_id" class="text-subtitle-2 ml-1">
                  : {{ company.carriers.filter(c => c.id === shipping_method.sf_carrier_account_id)[0].name }}
                </span>
              </div>
            </div>
          </v-card-title>
          <v-card-subtitle v-if="shipping_method" class="pb-0">
            <ExpandableChipGroup :items="shipping_method.countries" />
          </v-card-subtitle>
        </v-col>
        <v-col cols="2" class="d-flex justify-center align-center" v-if="existing_method">
          <v-btn 
            icon 
            outlined 
            color="error" 
            @click="$emit('remove')"
            class="remove-btn"
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <!-- Existing Method Details -->
      <template v-if="existing_method">
        <v-divider class="mx-4 my-2"></v-divider>
        <v-card-text class="method-details">
          <v-row>
            <v-col cols="12" md="3">
              <div class="detail-section">
                <div class="detail-label">Carrier</div>
                <div>{{ shipping_method.carrier_description }}</div>
                <div class="mt-2">{{ shipping_method.service_description }}</div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="detail-section">
                <div class="detail-label">Label</div>
                <div>{{ shipping_method.label.description }}</div>
              </div>
            </v-col>
            <v-col cols="12" md="3" v-if="shipping_method.addons.length">
              <div class="detail-section">
                <div class="detail-label">Add-ons</div>
                <div>{{ shipping_method.addons.map(a => a.description).toString() }}</div>
              </div>
            </v-col>
            <v-col cols="12" md="3">
              <div class="detail-section">
                <div class="detail-label">Weight Range</div>
                <div>Min {{ shipping_method.min_weight_in_grams }} g</div>
                <div>Max {{ shipping_method.max_weight_in_grams }} g</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </template>

      <!-- Expandable Form Section -->
      <template v-if="is_expanded || preset_country">
        <v-divider class="mx-4 my-2" v-if="!existing_method"></v-divider>
        <v-card-text class="form-section">
          <v-row>
            <!-- Carrier Account Selection -->
            <v-col cols="12">
              <v-select
                v-model="carrier"
                :items="company.carriers"
                item-text="name"
                item-value="id"
                label="Carrier account"
                no-data-text="No carrier accounts available. Add a carrier to get started"
                outlined
                rounded
                dense
                hide-details="auto"
                class="mb-4"
              />
            </v-col>

            <!-- Loading State -->
            <template v-if="carrier && !shipping.carriers[carrier]">
              <v-col cols="12" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="32"
                />
              </v-col>
            </template>

            <!-- Carrier Form Fields -->
            <template v-else>
              <!-- Carrier Company -->
              <v-col v-if="carrier" cols="12">
                <v-select
                  v-model="carrier_id"
                  :items="shipping.carriers[carrier]"
                  item-text="description"
                  return-object
                  label="Carrier company"
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  class="mb-4"
                />
              </v-col>

              <!-- Service -->
              <v-col cols="12" v-if="carrier_id">
                <v-select
                  v-model="service_id"
                  :items="preset_country ? get_available_methods({ carrier_account_id: carrier, carrier_id: carrier_id.id, country: preset_country}) : carrier_id.services"
                  item-text="uniqueDescription"
                  return-object
                  label="Service"
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  class="mb-4"
                />
              </v-col>

              <v-col cols="12" v-if="service_id && carrier_id.id === 'dhl_parcel_de'">
                <v-text-field
                    rounded
                    outlined
                    dense
                    type="text"
                    :rules="[v => !!v || 'Required']"
                    v-model="billing_number"
                    label="Billing Number"
                    hide-details="auto"
                    class="mb-4"
                  />

              </v-col>

              <!-- Payment Method (Unifaun) -->
              <v-col cols="12" v-if="service_id && company.carriers.find(i => i.id === carrier).type === 'unifaun'">
                <v-card outlined rounded="lg" class="payment-method-card">
                  <v-card-title class="text-subtitle-1 font-weight-medium">
                    <v-icon left color="primary">mdi-credit-card</v-icon>
                    Payment method
                  </v-card-title>
                  <v-card-text class="d-flex align-center">
                    <span class="text-body-2">Credit w/ delivery notice</span>
                    <v-switch
                      v-model="paymentMethodType"
                      true-value="INVO"
                      false-value="INVODN"
                      class="mx-2"
                      hide-details
                    />
                    <span class="text-body-2">Credit w/o delivery notice</span>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Add-ons -->
              <v-col cols="12" v-if="service_id && all_addons.length">
                <v-select
                  v-model="addons"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :items="all_addons"
                  item-text="description"
                  return-object
                  label="Add-ons"
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  class="mb-4"
                />
              </v-col>

              <!-- Add-on Values -->
              <v-col 
                cols="12" 
                v-for="(addon, ai) in addons.filter(addon => addon.values)"
                :key="`addon-${ai}`"
              >
                <v-card outlined rounded="lg" class="mb-4 addon-card">
                  <v-card-title class="text-subtitle-2 font-weight-medium py-2">
                    {{ addon.description }}
                  </v-card-title>
                  <v-card-text class="py-2">
                    <v-row 
                      v-for="(key, vk) in addon.values"
                      :key="`value-${vk}`"
                      dense
                    >
                      <v-col cols="12">
                        <v-combobox
                          small-chips
                          outlined
                          rounded
                          dense
                          v-model="key.expects"
                          :items="config.addon_expected_values"
                          :label="`${key.description} expects`"
                          hint="Choose a variable in the list or type a custom value"
                          persistent-hint
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Label Type -->
              <v-col cols="12">
                <v-select
                  v-model="label_type"
                  v-if="service_id"
                  :items="get_carrier_print_format(carrier)"
                  :rules="[v => !!v || 'Required']"
                  return-object
                  item-text="description"
                  label="Label type"
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  class="mb-4"
                >
                  <template v-slot:item="{ item }">
                    <div class="d-flex flex-column">
                      {{ item.description }}
                      <div v-if="get_used_label_formats.some(a => a === item.title)" class="text-caption primary--text">
                        Previously used
                      </div>
                    </div>
                  </template>
                </v-select>
              </v-col>

              <!-- Weight Range -->
              <template v-if="!preset_country">
                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    dense
                    type="number"
                    :rules="[v => !!v || v === 0 || 'Required']"
                    v-model="min_weight"
                    label="Min weight per parcel (grams)"
                    hint="If the products in a parcel weighs less than this, the parcel weight will be set to this"
                    persistent-hint
                    hide-details="auto"
                    class="mb-4"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    rounded
                    outlined
                    dense
                    type="number"
                    :rules="[v => !!v || 'Required']"
                    v-model="max_weight"
                    label="Max weight per parcel (grams)"
                    hint="If the products in an order weights more than this, the order will be split up on multiple parcels."
                    persistent-hint
                    hide-details="auto"
                    class="mb-4"
                  />
                </v-col>
              </template>

              <!-- Countries -->
              <v-col cols="12" v-if="!preset_country">
                <v-autocomplete
                  v-model="countries"
                  v-if="service_id"
                  multiple
                  chips
                  small-chips
                  deletable-chips
                  :items="route_countries"
                  label="To countries"
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  class="mb-4"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 5" small>
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 5"
                      class="grey--text text-caption"
                    >
                      (+{{ countries.length - 5 }} others)
                    </span>
                  </template>
                  <template v-slot:append-outer>
                    <v-btn
                      small
                      outlined
                      rounded
                      @click="countries.length ? countries = [] : countries = route_countries"
                    >
                      {{ countries.length ? 'Clear all' : 'Select all'}}
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>

              <!-- Save Button -->
              <v-col 
                cols="12"
                class="text-center"
                v-if="service_id && (countries.length || preset_country)"
              >
                <v-btn
                  color="primary"
                  type="submit"
                  rounded
                  elevation="1"
                  class="px-6"
                >
                  <v-icon left>{{ preset_country ? 'mdi-plus-circle' : 'mdi-content-save' }}</v-icon>
                  {{ preset_country ? 'Add to parcel' : 'Save'}}
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
      </template>

      <!-- Expand/Collapse Button -->
      <div class="text-center py-2" v-if="!preset_country">
        <v-btn  
          icon
          color="primary"
          @click="is_expanded = !is_expanded"
          class="expand-btn"
        >
          <v-icon>{{ is_expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ExpandableChipGroup from '@/components/ExpandableChipGroup'

export default {
  components: {
    ExpandableChipGroup
  },
  props: {
    shipping_method: {
      type: Object,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    store: {
      type: Object,
      required: false,
      default: null
    },
    preset_country: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      shipping: state => state.shipping,
      config: state => state.app.config,
    }),
    ...mapGetters({
      get_available_methods: 'shipping/get_available_methods',
      get_carrier_print_format: 'app/get_carrier_print_format',
      get_used_label_formats: 'warehouse/get_used_label_formats'
    }),
    existing_method(){
      return this.shipping_method?.service_id
    },
    test(){
      return this.carrier_id.services.reduce((tot, service) => (
        tot.concat(service.addons.filter(addon => !tot.some(s => s.id === addon.id)))
      ), [])
    },
    all_addons(){
      return this.service_id.addons.concat(this.config.addons[this.service_id.id] || [])
    },
    route_countries(){
      if(this.preset_country) return []
      let countries = this.shipping_method.countries
      if(!this.carrier_id || !this.service_id) return countries
      const serviced_countries = this.service_id.routes
        .reduce((tot, route) => tot.concat(route.to), [])
      countries = countries.filter(country => serviced_countries.indexOf(country) !== -1)
      for(const addon of this.addons){
        if(!addon.routes) continue
        const allowed = addon.routes.reduce((tot, route) => tot.concat(route.to), [])
        countries = countries.filter(country => allowed.indexOf(country) !== -1)
      }
      if(!this.existing_method){
        const mapped_countries = this.store.shipping_map
          .filter(a => a.title === this.shipping_method.title)
          .reduce((tot, method) => tot.concat(method.countries), [])
        countries = countries.filter(country => mapped_countries.indexOf(country) === -1)
      }
      countries.sort()
      return countries
    }
  },
  watch: {
    carrier: async function(id){
      if(id && !this.shipping.carriers[id]) await this.$store.dispatch('shipping/get_carrier_partners', id)
        if(this.existing_method){
          this.carrier_id = this.shipping.carriers[this.shipping_method.sf_carrier_account_id].find(c => c.id === this.shipping_method.carrier_id)
          this.service_id = this.carrier_id.services.find(s => s.id === this.shipping_method.service_id)
          this.addons = this.service_id.addons
            .filter(a => this.shipping_method.addons
              .some(s => s.id === a.id)
            ).map(addon => ({ ...addon, values: addon.values.map(value => ({
              ...value,
              expects: this.shipping_method.addons.find(a => a.id === addon.id).values.find(v => v.id === value.id).expects
            }))}))
        }
    },
    route_countries: function(countries){
      this.countries = countries
    },
    is_expanded: function(expanded){
      if(expanded && this.existing_method){
        const sm = this.shipping_method
        this.carrier = sm.sf_carrier_account_id
        this.paymentMethodType = sm.paymentMethodType
        this.billing_number = sm.billing_number
        this.min_weight = sm.min_weight_in_grams
        this.max_weight = sm.max_weight_in_grams
        this.label_type = { 
          title: sm.label.type,
          description: sm.label.description,
        }
      }
    }
  },
  data() {
    return {
      billing_number: '',
      is_expanded: false,
      carrier: '',
      carrier_id: null,
      service_id: null,
      paymentMethodType: 'INVO',
      addons: [],
      countries: [],
      min_weight: 0,
      max_weight: 2000,
      show_all_countries: false,
      label_type: null,
    }
  },
  methods: {
    save_method(){
      if(!this.$refs.methods_form.validate()) return
      const req = {
        title: this.shipping_method?.title || null,
        sf_carrier_account_id: this.carrier,
        carrier_id: this.carrier_id.id,
        carrier_description: this.carrier_id.description,
        carrier_name: this.carrier_id.carrier_name,
        service_id: this.service_id.id,
        service_description: this.service_id.uniqueDescription,
        billing_number: this.billing_number,
        addons: this.addons.map(a => {
          delete a.routes
          return a
        }),
        countries: this.countries,
        paymentMethodType: this.paymentMethodType,
        min_weight_in_grams: Number(this.min_weight),
        max_weight_in_grams: Number(this.max_weight),
        label: {
          type: this.label_type.title,
          description: this.label_type.description,
        },
      }
      this.$emit('save', req)
      this.billing_number = ''
      this.is_expanded = false
      this.carrier_id = null
      this.carrier = ''
      this.service_id = null
      this.addons = []
      this.countries = []
    }
  }
}
</script>

<style lang="scss" scoped>
.shipping-map-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  
  &:hover:not([disabled]) {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
  
  .method-details {
    padding-top: 8px;
    padding-bottom: 8px;
    
    .detail-section {
      background-color: rgba(0, 0, 0, 0.02);
      border-radius: 8px;
      padding: 12px;
      height: 100%;
      
      .detail-label {
        font-weight: 500;
        color: var(--v-primary-base);
        margin-bottom: 4px;
        font-size: 0.85rem;
      }
    }
  }
  
  .form-section {
    animation: fadeIn 0.4s ease-out;
  }
  
  .payment-method-card,
  .addon-card {
    background-color: rgba(0, 0, 0, 0.02);
    transition: box-shadow 0.2s ease;
    
    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05) !important;
    }
  }
  
  .remove-btn {
    transition: all 0.2s ease;
    
    &:hover {
      background-color: rgba(var(--v-error-base-rgb), 0.1);
      transform: scale(1.1);
    }
  }
  
  .expand-btn {
    transition: transform 0.3s ease;
    
    &:hover {
      transform: scale(1.2);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
