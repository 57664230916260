<template>
  <v-card outlined rounded="lg" class="price-list-editor mb-6" :loading="loading" :disabled="loading">
    <v-card-title class="text-h6 font-weight-medium py-4">
      <v-icon left color="primary" size="24">mdi-currency-usd</v-icon>
      Price List Editor
      <v-spacer></v-spacer>
      <v-chip-group
        v-model="selectedPriceListType"
        mandatory
        active-class="primary--text"
      >
        <v-chip
          :value="'formula'"
          outlined
          label
          small
        >
          Formula Based
        </v-chip>
        <v-chip
          :value="'weight'"
          outlined
          label
          small
        >
          Weight Based
        </v-chip>
      </v-chip-group>
    </v-card-title>
    
    <v-card-text class="pb-4">
      <v-data-table
        :headers="selectedPriceListType === 'weight' ? weightHeaders : formulaHeaders"
        :items="sortedPriceListData"
        :items-per-page="10"
        class="elevation-1 rounded-lg"
        :footer-props="{
          'items-per-page-options': [5, 10, 15, -1],
          'items-per-page-text': 'Zones per page'
        }"
      >
        <!-- Zone Name Column -->
        <template #[`item.zone_name`]="{ item }">
          <v-text-field
            v-model="item.zone_name"
            dense
            outlined
            hide-details
            class="my-1"
            @input="updateParent"
          ></v-text-field>
        </template>
        
        <!-- Countries Column -->
        <template #[`item.to_countries`]="{ item }">
          <div :data-identifier="getItemIdentifier(item)">
            <div class="d-flex flex-wrap align-center mb-1">
              <template v-for="(country, index) in item.to_countries">
                <v-chip
                  small
                  class="mr-1 mb-1"
                  v-if="index < 5 || showAllCountries[getItemIdentifier(item)]"
                  :key="`country-${getItemIdentifier(item)}-${index}`"
                  close
                  @click:close="removeCountry(item, index)"
                >
                  {{ country }}
                </v-chip>
              </template>
              <v-btn
                x-small
                outlined
                rounded
                v-if="!showAllCountries[getItemIdentifier(item)] && (item.to_countries.length - 5) > 0"
                @click="showAllCountries[getItemIdentifier(item)] = true"
                class="ml-1"
              >
                Show {{ item.to_countries.length - 5 }} more
              </v-btn>
              <v-btn
                x-small
                outlined
                rounded
                v-if="showAllCountries[getItemIdentifier(item)] && item.to_countries.length > 5"
                @click="showAllCountries[getItemIdentifier(item)] = false"
                class="ml-1"
              >
                Show less
              </v-btn>
            </div>
            
            <!-- Add Country Button and Input -->
            <div class="d-flex align-center mt-1">
              <v-btn
                x-small
                outlined
                color="primary"
                class="add-country-btn"
                v-if="!showAddCountry[getItemIdentifier(item)]"
                @click="toggleAddCountry(getItemIdentifier(item))"
              >
                <v-icon x-small left>mdi-plus</v-icon>
                Add country
              </v-btn>
              
              <div v-if="showAddCountry[getItemIdentifier(item)]" class="d-flex align-center add-country-input-container">
                <v-text-field
                  v-model="newCountry[getItemIdentifier(item)]"
                  dense
                  outlined
                  hide-details
                  placeholder="e.g. SE, US, * for rest"
                  class="add-country-input mr-2"
                  @keydown.enter="addCountry(item)"
                  ref="countryInput"
                ></v-text-field>
                <v-btn
                  x-small
                  icon
                  color="primary"
                  @click="addCountry(item)"
                  class="mr-1"
                >
                  <v-icon x-small>mdi-check</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  icon
                  @click="toggleAddCountry(getItemIdentifier(item))"
                >
                  <v-icon x-small>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </template>
        
        <!-- Price per gram Column (Formula-based) -->
        <template #[`item.price_per_gram`]="{ item }">
          <v-text-field
            v-model.number="item.price_per_gram"
            type="number"
            min="0"
            dense
            outlined
            hide-details
            hide-spin-buttons
            class="my-1"
            @input="updateParent"
          ></v-text-field>
        </template>
        
        
        <!-- Max Weight Column (Weight-based) -->
        <template #[`item.max_weight_in_grams`]="{ item }">
          <v-text-field
            v-model.number="item.max_weight_in_grams"
            type="number"
            min="0"
            dense
            outlined
            hide-details
            hide-spin-buttons
            class="my-1"
            @input="updateParent"
          ></v-text-field>
        </template>
        
        <!-- Price per parcel Column -->
        <template #[`item.price_per_parcel`]="{ item }">
          <v-text-field
            v-model.number="item.price_per_parcel"
            type="number"
            step="0.01"
            min="0"
            dense
            outlined
            hide-details
            hide-spin-buttons
            class="my-1"
            @input="updateParent"
          ></v-text-field>
        </template>
        
        <!-- Actions Column -->
        <template #[`item.actions`]="{ index }">
          <v-btn
            icon
            color="error"
            @click="removeZone(index)"
            small
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        
        <!-- Add new zone button at the bottom -->
        <template #footer>
          <v-btn
            color="primary"
            text
            @click="addNewZone"
            class="ma-2"
          >
            <v-icon left>mdi-plus</v-icon>
            Add Zone
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PriceListEditor',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedPriceListType: 'formula',
      formulaHeaders: [
        { text: 'Zone Name', value: 'zone_name', width: '25%' },
        { text: 'Countries', value: 'to_countries', width: '40%' },
        { text: 'Price per gram', value: 'price_per_gram', width: '15%' },
        { text: 'Price per parcel', value: 'price_per_parcel', width: '15%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '5%' }
      ],
      weightHeaders: [
        { text: 'Countries', value: 'to_countries', width: '50%' },
        { text: 'Max Weight (g)', value: 'max_weight_in_grams', width: '25%' },
        { text: 'Price per parcel', value: 'price_per_parcel', width: '20%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '5%' }
      ],
      priceListData: [],
      showAllCountries: {},
      showAddCountry: {},
      newCountry: {}
    }
  },
  computed: {
    isWeightBased() {
      // Auto-detect if the price list is weight-based by checking if any item has max_weight_in_grams
      // if (this.priceListData && this.priceListData.length > 0) {
      //   const hasWeightProperty = this.priceListData.some(item => 'max_weight_in_grams' in item);
      //   if (hasWeightProperty) {
      //     // this.selectedPriceListType = 'weight';
      //     return true;
      //   }
      // }
      return this.priceListData.some(item => item.max_weight_in_grams);
    },
    sortedPriceListData() {
      return [...this.priceListData].sort((a, b) => {
        if (this.isWeightBased) {
          console.log('is weight based')
          return (a.max_weight_in_grams || 0) - (b.max_weight_in_grams || 0);
        } else {
          // Extract zone numbers from zone names for formula-based price lists
          const aMatch = a.zone_name?.match(/Zone\s+(\d+)/i);
          const bMatch = b.zone_name?.match(/Zone\s+(\d+)/i);
          
          const aNum = aMatch ? parseInt(aMatch[1]) : Infinity;
          const bNum = bMatch ? parseInt(bMatch[1]) : Infinity;
          
          return aNum - bNum;
        }
      });
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal && newVal.length) {
          // Create a deep copy to avoid direct mutation of props
          this.priceListData = JSON.parse(JSON.stringify(newVal));
          
          // Auto-detect if the price list is weight-based
          const hasWeightProperty = this.priceListData.some(item => 'max_weight_in_grams' in item);
          this.selectedPriceListType = hasWeightProperty ? 'weight' : 'formula';
          
          // Initialize showAllCountries, showAddCountry and newCountry objects
          this.priceListData.forEach(item => {
            const identifier = hasWeightProperty 
              ? `weight_${item.max_weight_in_grams}`
              : item.zone_name;
              
            if (identifier) {
              this.$set(this.showAllCountries, identifier, false);
              this.$set(this.showAddCountry, identifier, false);
              this.$set(this.newCountry, identifier, '');
            }
          });
        } else {
          this.priceListData = [];
        }
      },
      immediate: true
    },
    // When the price list type changes, clear the data and create a new empty entry
    selectedPriceListType: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && this.priceListData.length === 0) {
          this.addNewZone();
        }
      }
    }
  },
  methods: {
    getItemIdentifier(item) {
      // For formula-based price lists, use zone_name
      // For weight-based price lists,  max_weight_in_grams as identifier
      return this.isWeightBased 
        ? `weight_${item.max_weight_in_grams}`
        : item.zone_name;
    },
    
    toggleAddCountry(identifier) {
      this.$set(this.showAddCountry, identifier, !this.showAddCountry[identifier]);
      
      // Focus the input field when showing
      if (this.showAddCountry[identifier]) {
        this.$nextTick(() => {
          const inputs = this.$refs.countryInput;
          if (Array.isArray(inputs)) {
            const input = inputs.find(el => el.$el.closest('[data-identifier="' + identifier + '"]'));
            if (input) input.focus();
          }
        });
      }
    },
    
    removeZone(index) {
      // We need to find the actual item in priceListData that corresponds to the index in sortedPriceListData
      const zoneToRemove = this.sortedPriceListData[index];
      const actualIndex = this.priceListData.findIndex(item => item === zoneToRemove);
      
      if (actualIndex !== -1) {
        this.priceListData.splice(actualIndex, 1);
      }
      this.$emit('input', this.priceListData)
    },
    
    addNewZone() {
      let newZone;
      
      if (this.isWeightBased) {
        // Create a weight-based price list entry
        newZone = {
          to_countries: [],
          max_weight_in_grams: 1000,
          price_per_parcel: 0
        };
      } else {
        // Create a formula-based price list entry
        newZone = {
          zone_name: 'New Zone',
          to_countries: [],
          price_per_gram: 0,
          price_per_parcel: 0
        };
      }
      
      this.priceListData.push(newZone);
      
      // Initialize the identifier-related properties
      const identifier = this.getItemIdentifier(newZone);
      if (identifier) {
        this.$set(this.showAllCountries, identifier, false);
        this.$set(this.showAddCountry, identifier, false);
        this.$set(this.newCountry, identifier, '');
      }
      
      this.$emit('input', this.priceListData);
    },
    
    addCountry(item) {
      const identifier = this.getItemIdentifier(item);
      const country = this.newCountry[identifier];
      if (country && country.trim() && !item.to_countries.includes(country.trim())) {
        item.to_countries.push(country.trim());
      }
      this.newCountry[identifier] = '';
      
      // Hide the input field after adding
      this.$set(this.showAddCountry, identifier, false);
      this.$emit('input', this.priceListData)
    },
    
    removeCountry(item, index) {
      item.to_countries.splice(index, 1);
      this.$emit('input', this.priceListData)
    },
    
    updateParent() {
      this.$emit('input', this.priceListData);
    }
  }
}
</script>

<style lang="scss" scoped>
.price-list-editor {
  background-color: rgba(0, 0, 0, 0.02);
  transition: box-shadow 0.2s ease;
  
  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  }
  
  /* Hide spinner buttons for number inputs */
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  ::v-deep input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  ::v-deep input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  .add-country-btn {
    font-size: 0.75rem;
    height: 24px;
    opacity: 0.7;
    
    &:hover {
      opacity: 1;
    }
  }
  
  .add-country-input-container {
    max-width: 200px;
  }
  
  .add-country-input {
    font-size: 0.75rem;
  }
}
</style>
