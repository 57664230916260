<template>
  <v-card
    class="carrier-service-component"
    elevation="2"
    rounded="lg"
    :disabled="!get_plan || !get_plan.allow_auto_carrier"
  >
    <!-- Service Header -->
    <v-card-title class="service-header">
      <div class="d-flex align-center justify-space-between w-100">
        <div class="d-flex align-center">
          <v-icon 
            :color="is_used ? 'success' : 'primary'" 
            class="mr-3"
            size="28"
          >
            {{ is_used ? 'mdi-check-circle' : 'mdi-truck-delivery' }}
          </v-icon>
          <div>
            <div class="text-h6 font-weight-medium">
              {{ service.display_name || service.name }}
              <v-chip
                color="success"
                v-if="is_used"
                small
                class="ml-2"
              >
                Active
              </v-chip>
            </div>
            <div class="text-subtitle-2 grey--text text--darken-1">
              {{ service.description }}
            </div>
            <v-chip 
              outlined 
              :color="service.is_domestic ? 'primary' : 'info'"
              class="coverage-chip"
            >
              <v-icon left>
                {{ service.is_domestic ? 'mdi-home-variant' : 'mdi-earth' }}
              </v-icon>
              <span v-if="service.from_country && service.to_countries">
                From {{ service.from_country }} to 
                {{ 
                  service.to_countries.length ?  
                  `${service.to_countries.join(', ')}` 
                  : service.restricted_countries.length ? 
                  `all countries except ${service.restricted_countries.join(', ')}`
                  : 'all countries'
                }}
              </span>
              <span v-else-if="service.is_domestic">
                Domestic
              </span>
              <span v-else>
                International
              </span>
            </v-chip>
          </div>
        </div>
        <v-btn
          outlined
          rounded
          color="primary"
          @click="expanded = !expanded"
          class="toggle-btn"
        >
          {{ expanded ? `Close` : is_used ? 'Edit' : `Add to shop`}}
          <v-icon right>
            {{ expanded ? `mdi-chevron-up` : `mdi-chevron-down`}}
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>

    <!-- Service Coverage Info -->
    <v-card-subtitle class="service-coverage">
      
    </v-card-subtitle>

    <!-- Expanded Form Section -->
    <div v-if="expanded" class="expanded-section">
      <v-divider class="mx-4"></v-divider>
      
      <!-- Loading State -->
      <template v-if="!shipping.carriers[carrier.id]">
        <div class="text-center py-6">
          <v-progress-circular
            indeterminate
            color="primary"
            size="42"
            width="3"
          ></v-progress-circular>
          <div class="text-body-2 grey--text text--darken-1 mt-3">Loading carrier data...</div>
        </div>
      </template>
      
      <!-- Form Content -->
      <template v-else>
        <v-form
          ref="service_form"
          @submit.prevent="save_method"
          class="form-content"
        >
<v-expansion-panels v-if="existing_installs && existing_installs.length" v-model="import_expanded_panel" class="mb-6">
  <v-expansion-panel class="advanced-panel">
    <v-expansion-panel-header class="py-4">
      <div class="d-flex align-center">
        <v-icon color="primary" class="mr-3" size="24">mdi-import</v-icon>
        <span class="text-h6 font-weight-medium">Import Settings</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pa-4">
      <v-row>
        <v-col cols="12" md="8">
          <v-select
            :items="existing_installs"
            item-text="store_name"
            item-value="service"
            label="Select store to import settings from"
            v-model="copy_from"
            outlined
            rounded
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-center">
          <v-btn
            color="primary"
            rounded
            @click="copy_settings"
            class="px-6"
          >
            <v-icon left>mdi-content-copy</v-icon>
            Import
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</v-expansion-panels>

          <!-- Basic Settings -->
          <v-card outlined rounded="lg" class="mb-6 settings-card">
            <v-card-title class="text-h6 font-weight-medium py-4">
              <v-icon left color="primary" size="24">mdi-cog</v-icon>
              Basic Settings
            </v-card-title>
            <v-card-text class="pb-4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Name"
                    :rules="[v => !!v || 'Required']"
                    v-model="new_service.customer_facing_name"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Description"
                    v-model="new_service.customer_facing_description"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4"> 
                  <v-select
                    v-model="new_service.label.type"
                    :items="get_carrier_print_format(carrier.id)"
                    :rules="[v => !!v || 'Required']"
                    item-value="title"
                    item-text="description"
                    label="Label type"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  >
                    <template v-slot:item="{ item }">
                      <div class="d-flex flex-column">
                        {{ item.description }}
                        <div v-if="item.is_previously_used" class="text-caption primary--text">
                          Previously used
                        </div>
                      </div>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    type="number"
                    :rules="[v => !!v || 'Required']"
                    label="Packaging standard weight (grams)"
                    v-model="new_service.standard_packaging_weight_in_grams"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  />
                </v-col>
                <v-col cols="12" md="4" class="d-flex">
                  <v-text-field
                    type="number"
                    :rules="[v => !!v || 'Required']"
                    messages="Prices provided to Shopify Checkout will be multiplied with this factor"
                    label="Price Multiplicator"
                    v-model="new_service.base_price_multiplier"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="service.carrier === 'dhl_parcel_de'">
                  <v-text-field
                    type="number"
                    :rules="[v => !!v && v.length === 14 || 'Must be 14 figures']"
                    label="Billing number"
                    v-model="new_service.billing_number"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <template v-if="service.price_list_template">
            <v-expansion-panels v-model="ai_recognition_expanded_panel" class="mb-6">
              <v-expansion-panel class="advanced-panel" :disabled="on_reading_pdf">
                <v-expansion-panel-header class="py-4">
                  <div class="d-flex align-center">
                    <v-icon color="primary" class="mr-3" size="24">mdi-brain</v-icon>
                    <span class="text-h6 font-weight-medium">AI Price Recognition (Beta)</span>
                    <ToolTip class="ml-2">As this service uses individual price lists per customer, submit the price list for AI recognition. If you want to edit prices, such as adding VAT to them adjust the prompt accordingly. Any price multipliers will still be applied to these prices.</ToolTip>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pa-4">
                  <v-row>
                    <v-col cols="12">
                      <v-file-input 
                        :loading="on_reading_pdf"
                        label="Price list PDF"
                        v-model="price_list_pdf"
                        accept="application/pdf"
                        outlined
                        rounded
                        prepend-icon="mdi-file-pdf-box"
                        placeholder="Upload your price list PDF"
                        hide-details="auto"
                        class="mb-4"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="service.price_list_template.prompt"
                        outlined
                        rounded
                        label="Prompt"
                        placeholder="Enter instructions for AI processing"
                        :loading="on_reading_pdf"
                        hide-details="auto"
                        rows="3"
                        class="mb-4"
                      />
                    </v-col>
                    <v-col cols="12" >
                      <v-btn
                        @click="on_upload_pdf"
                        :loading="on_reading_pdf"
                        color="primary"
                        rounded
                        elevation="2"
                        min-width="120"
                        class="px-6"
                      >
                        <v-icon left>mdi-magnify</v-icon>
                        {{ on_upload_error || reading_success ? 'Retry' : 'Analyze' }}
                      </v-btn>
                      <p class="text-caption" v-if="on_reading_pdf">
                        {{ loading_message }}
                      </p>
                      <v-alert
                        type="info"
                        dense
                        class="mt-2"
                        v-if="reading_success"
                        >AI is still experimental. If the results below look weird - simply click Retry. Every run can present a different return, and sometimes it gets it right on the 3rd try.</v-alert>
                    </v-col>
                    <v-col
                      v-if="!!on_upload_error"
                      cols="12">
                      <v-alert
                      type="warning"
                      dense>
                    Analyze failed. Sometimes you may need to retry a few times, or you need to try and change the prompt.
                      </v-alert>
                      <p class="text-caption my-2">Full message: {{  on_upload_error }}</p>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            
            <!-- Price List Editor -->
            <PriceListEditor
              v-model="new_service.price_list"
              :loading="on_reading_pdf"
            />
          </template>

          <!-- Customer Price List -->
          <template v-if="service.customer_defined_weight_prices">
            <v-card outlined rounded="lg" class="mb-6 price-list-card">
              <v-card-title class="text-h6 font-weight-medium py-4">
                <v-icon left color="primary" size="24">mdi-currency-usd</v-icon>
                Customer Price List
                <ToolTip>As this service uses individual price lists per customer, submit the prices you wish to offer your end customers inclusive of VAT. Any price multipliers will still be applied to these prices.</ToolTip>
              </v-card-title>
              <v-card-text class="pb-4">
                <v-simple-table class="price-table">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Max Weight (grams)</th>
                        <th class="text-left">Price (EUR, inclusive of VAT)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in new_service.customer_defined_weight_prices" :key="`price-${index}`">
                        <td>{{ item.max_weight_in_grams }}</td>
                        <td>
                          <v-text-field
                            v-model.number="item.price"
                            type="number"
                            step="0.01"
                            min="0"
                            hide-details
                            outlined
                            rounded
                            class="price-input"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </template>

          <!-- Add-ons -->
          <v-card v-if="addons && addons.length" outlined rounded="lg" class="mb-6 addons-card">
            <v-card-title class="text-h6 font-weight-medium py-4">
              <v-icon left color="primary" size="24">mdi-package-variant-plus</v-icon>
              Add-ons
            </v-card-title>
            <v-card-text class="pb-4">
              <v-select
                v-model="new_service.addons"
                multiple
                chips
                small-chips
                deletable-chips
                :items="addons"
                :item-text="v => (`${v.description}${v.base_price_in_cents ? ` ( + ${v.currency} ${(v.base_price_in_cents / 100)} / parcel)`: ''}`)"
                return-object
                label="Available add-ons"
                outlined
                rounded
                hide-details="auto"
              />
            </v-card-text>
          </v-card>

          <!-- Advanced Settings -->
          <v-expansion-panels v-model="advanced_expanded_panel" class="mb-6">
            <v-expansion-panel class="advanced-panel">
              <v-expansion-panel-header class="py-4">
                <div class="d-flex align-center">
                  <v-icon color="primary" class="mr-3" size="24">mdi-tune</v-icon>
                  <span class="text-h6 font-weight-medium">Advanced Settings</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-4">
                <!-- Product Tags -->
                <div class="mb-6">
                  <div 
                    v-if="service.addon_prices_by_line_items"
                    class="text-h6 font-weight-medium mb-3"
                    >
                    <v-icon left color="primary">mdi-tag</v-icon>
                    Product Tags
                  </div>
                  <v-alert
                    type="info"
                    outlined
                    class="mb-3"
                    v-for="(tag, key) in service.addon_prices_by_line_items"
                    :key="`tag-${key}`"
                  >
                    <div class="d-flex align-center">
                      <span>
                        If one or more products in a shipment is tagged with <code>{{ tag.product_tag }}</code>, {{ tag.currency }} {{ tag.base_price_in_cents / 100 }} will be added to the rate for {{  tag.name }}.
                      </span>
                      <ToolTip>{{ tag.description }}</ToolTip>
                    </div>
                  </v-alert>
                </div>

                <!-- Shipping Groups -->
                <div class="mb-6">
                  <div class="text-h6 font-weight-medium mb-3">
                    <v-icon left color="primary">mdi-package-variant</v-icon>
                    Shipping Groups
                  </div>
                  <v-alert
                    type="info"
                    outlined
                    class="mb-4"
                  >
                    <p class="mb-3">You can tag a product with <code>shipping_group_{1-5}</code>, for example <code>shipping_group_1</code>. If an order contains items with this tag, those items will be shipped in a separate parcel. Only methods explicitly made available for each group will be offered.</p>
                    <p class="mb-0">You may also tag a product with <code>optional_group_{1-5}</code>, for example <code>optional_group_1</code>. For example, a poster may be tagged <code>shipping_group_1</code>, and a sticker may then be tagged <code>optional_group_1</code>. If someone orders just a poster and sticker both these will be shipped together. If someone orders a poster, sticker and t-shirt the sticker will be shipped with the t-shirt. If the sticker is not tagged <code>optional_group_1</code> it will be shipped by itself in its own parcel separate from the poster.</p>
                  </v-alert>
                  <v-select
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    :rules="[v => !!v.length || 'Required']"
                    :items="shipping_group_options"
                    v-model="new_service.shipping_groups"
                    label="This method will be available for items tagged with"
                    outlined
                    rounded
                    hide-details="auto"
                    class="mb-6"
                  />
                </div>

                <!-- Lowest Denominating Name -->
                <v-card outlined rounded="lg" class="mb-4">
                  <v-card-title class="text-h6 font-weight-medium py-4">
                    <v-icon left color="primary" size="24">mdi-format-title</v-icon>
                    Lowest Denominating Name
                  </v-card-title>
                  <v-card-subtitle class="pb-3">
                    The lowest denominating name and description is displayed for a customer when an order contains items in different shipping groups,
                    and two or more different shipping services are being used in combination. If the rates have the same lowest denominating name, but different service names, 
                    the lowest denominating name will be presented to the customer at check out. Make sure keep these simple and exactly the same across multiple methods, such as 
                    "Tracked letter", "Untracked letter" etc.
                  </v-card-subtitle>
                  <v-card-text class="pb-4">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="new_service.lowest_denominating_name"
                          outlined
                          rounded
                          label="Lowest denominating name"
                          placeholder="Tracked letter"
                          hide-details="auto"
                          class="mb-4"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          v-model="new_service.lowest_denominating_description"
                          outlined
                          rounded
                          label="Lowest denominating description"
                          placeholder="Fastest option, tracking number provided"
                          hide-details="auto"
                          class="mb-4"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <!-- Action Buttons -->
          <div class="text-center action-buttons py-4">
            <v-btn
              :loading="loading"
              color="primary"
              rounded
              elevation="2"
              type="submit"
              x-large
              min-width="200"
              class="px-8"
            >
              <v-icon left>{{ is_used ? 'mdi-content-save' : 'mdi-plus-circle' }}</v-icon>
              {{ is_used ? 'Save Changes' : 'Add to Shop'}}
            </v-btn>
            
            <v-btn
              :loading="loading"
              text
              rounded
              color="error"
              v-if="is_used"
              @click="$emit('deactivate')"
              class="mt-4"
              min-width="200"
            >
              <v-icon left>mdi-close-circle</v-icon>
              Deactivate Service
            </v-btn>
          </div>
        </v-form>
      </template>
    </div>
  </v-card>
</template>

<script>
import ToolTip from '@/components/ToolTip.vue'
import PriceListEditor from '@/components/PriceListEditor.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    ToolTip,
    PriceListEditor
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    store: {
      type: Object,
      required: true,
      default: null
    },
    carrier_service: {
      type: Object,
      required: true,
      default: null
    },
    service: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      get_carrier_print_format: 'app/get_carrier_print_format',
      get_plan: 'warehouse/get_plan',
    }),
    ...mapState({
      company: s => s.warehouse.company,
      shipping: state => state.shipping,
    }),
    existing_installs: function(){
      return this.company.data_sources
        .map(ds => ds.id !== this.store.id && ds.auto_carrier_services.active_services
          .some(s => s.sf_id === this.service.id) ? ({
            store_name: ds.name,
            service: ds.auto_carrier_services.active_services
            .find(s => s.sf_id === this.service.id)
          }) : null
        ).filter(s => !!s)
    },
    carrier: function(){
      return this.company.carriers.find(c => c.type === this.service.carrier)
    },
    loading_message: function(){
      // Force re-evaluation by using timer_tick
      this.timer_tick;
      const seconds_ellapsed_reading = (new Date().getTime() - this.reading_pdf_started_at) / 1000
      // Remove console.log to avoid excessive logging
      return (seconds_ellapsed_reading > 60) ? 'Almost there. This was a heavy file.' 
                      : (seconds_ellapsed_reading > 40) ? 'Hang in there, still working on it..'  
                      : (seconds_ellapsed_reading > 20) ? 'Still analyzing..'
                      : (seconds_ellapsed_reading > 10) ? 'AI analyzing takes some time. Please be patient.'
                      : ''
    },
    active_shipping_groups: function(){ 
      return this.new_service.shipping_groups.filter(s => s !== 'No specific shipping group')
    },
    is_used: function () {
      return this.carrier_service?.active_services?.some(acs => acs.sf_id === this.service.id)
    },
    partner_service: function(){
      const sf_id = this.service.id
      return (this.shipping.carriers[this.carrier.id][0].services)
        .find(s => s.id === sf_id)
    },
    addons: function(){
      return this.service.allowed_addons
    }
  },
  watch: {
    expanded: async function(val){
      if(!val) return
      if(!this.shipping.carriers[this.carrier.id]) await this.$store.dispatch('shipping/get_carrier_partners', this.carrier.id)
    },
    on_reading_pdf: function(val) {
      // Start or stop the timer based on reading PDF status
      if (val) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    }
  },
  data() {
    return {
      expanded: false,
      copy_from: {},
      import_expanded_panel: null,
      ai_recognition_expanded_panel: null,
      advanced_expanded_panel: null,
      shipping_groups_disabled: true,
      on_reading_pdf: false,
      reading_pdf_started_at: 0,
      on_upload_error: '',
      reading_success: false,
      price_list_pdf: null,
      show_price_list_editor: false,
      timer_id: null,
      timer_tick: 0, // This property will change every second to trigger computed property updates
      new_service: {
        standard_packaging_weight_in_grams: 50,
        addons: [],
        shipping_groups: ['No specific shipping group'],
        customer_facing_name: '',
        customer_facing_description: '',
        lowest_denominating_name: '',
        lowest_denominating_description: '',
        base_price_multiplier: 1.00,
        price_list: [],
        label: {
          type: ''
        }
      },
      label_type: null,
      shipping_group_options: [
        'No specific shipping group',
        'shipping_group_1',
        'shipping_group_2',
        'shipping_group_3',
        'shipping_group_4',
        'shipping_group_5'
      ]
    }
  },
  methods: {
    copy_settings(){
      this.new_service = this.copy_from
    },
    startTimer() {
      // Start a timer that updates timer_tick every second
      this.stopTimer(); // Clear any existing timer
      this.timer_id = setInterval(() => {
        this.timer_tick++;
      }, 1000);
    },
    stopTimer() {
      // Stop the timer
      if (this.timer_id) {
        clearInterval(this.timer_id);
        this.timer_id = null;
      }
    },
    async on_upload_pdf(){
      this.on_upload_error = '';
      this.reading_success = false
      this.reading_pdf_started_at = new Date().getTime();
      
      try {
        const file = this.price_list_pdf
        if (!file) return;
        this.on_reading_pdf = true;
        
        // Read the file as ArrayBuffer and convert to base64 string
        const base64string = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            // Convert ArrayBuffer to base64 string
            const base64String = btoa(
              new Uint8Array(reader.result)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
            resolve(base64String);
            // resolve(reader.result)
          };
          reader.onerror = reject;
          reader.readAsArrayBuffer(file);
        });
        
        // Create the callable function with a longer timeout (5 minutes)
        const upload_price_list = this.$functions.httpsCallable('upload_price_list', { timeout: 300000 });
        const res = await upload_price_list({ 
          base64string, 
          prompt: this.service.price_list_template.prompt,  
          instructions: this.service.price_list_template.instructions
        });
        
        // Store the price list data and show the editor
        // Sort the data by zone number before displaying
        if(res.data.message) {
          this.on_reading_pdf = false;
          return this.on_upload_error = res.data.message;
        }
        this.reading_success = true
        this.new_service.price_list = res.data.sort((a, b) => {
          const aMatch = a.zone_name?.match(/Zone\s+(\d+)/i);
          const bMatch = b.zone_name?.match(/Zone\s+(\d+)/i);
          
          const aNum = aMatch ? parseInt(aMatch[1]) : Infinity;
          const bNum = bMatch ? parseInt(bMatch[1]) : Infinity;
          
          return aNum - bNum;
        });
      } catch (e) {
        this.on_upload_error = e.message;
      }
      
      this.on_reading_pdf = false;
    },
    
    async save_method(){
      if(!this.$refs.service_form.validate()) return
      const method = {
        ...this.new_service,
        base_price_multiplier: +this.new_service.base_price_multiplier || 1,
        sf_id: this.service.id,
        service_id: this.service.service_id,
        carrier_id: this.shipping.carriers[this.carrier.id][0].id,
        max_weight_in_grams: this.service.max_weight_in_grams || Infinity,
        min_weight_in_grams: this.service.min_weight_in_grams || 0,
        mandatory_addons: this.service.mandatory_addons || [],
        sf_carrier_account_id: this.carrier.id,
      }
      this.$emit('save', method)
      this.expanded = false
    }
  },
  async mounted(){
    console.log('service', this.service)
    
    this.new_service.customer_facing_description = this.service.description || ''
    this.new_service.customer_facing_name = this.service.name
    
    // Get active service if it exists
    const activeService = this.carrier_service?.active_services?.find(acs => acs.sf_id === this.service.id) || {};
    
    // Merge new_service with active service data
    this.new_service = {
      ...this.new_service,
      ...activeService
    }
    
    // Initialize customer_defined_weight_prices if it exists in the service
    if (this.service.customer_defined_weight_prices && !this.new_service.customer_defined_weight_prices) {
      this.new_service.customer_defined_weight_prices = JSON.parse(JSON.stringify(this.service.customer_defined_weight_prices));
    }
  },
  beforeDestroy() {
    // Clean up timer when component is destroyed
    this.stopTimer();
  }
}
</script>

<style lang="scss" scoped>
.carrier-service-component {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  margin-bottom: 24px;
  
  &:hover:not([disabled]) {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
  
  .service-header {
    padding: 16px 24px;
    
    @media (max-width: 600px) {
      padding: 12px 8px;
    }
  }
  
  .service-coverage {
    padding: 0 24px 16px;
    
    @media (max-width: 600px) {
      padding: 0 8px 12px;
    }
    
    .coverage-chip {
      font-size: 0.875rem;
      height: 32px;
    }
  }
  
  .expanded-section {
    animation: fadeIn 0.4s ease-out;
  }
  
  .form-content {
    padding: 24px;
    
    @media (max-width: 600px) {
      padding: 12px 8px;
    }
  }
  
  .settings-card,
  .price-list-card,
  .addons-card {
    background-color: rgba(0, 0, 0, 0.02);
    transition: box-shadow 0.2s ease;
    
    &:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
    }
  }
  
  .advanced-panel {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .toggle-btn {
    transition: all 0.2s ease;
    padding: 0 16px;
    height: 36px;
    
    &:hover {
      transform: translateY(-2px);
    }
    
    @media (max-width: 600px) {
      padding: 0 8px;
      font-size: 0.8rem;
    }
  }
  
  .action-buttons {
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  .price-table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow: hidden;
    
    th {
      background-color: #f5f5f5;
      font-weight: 600;
      padding: 12px 16px;
      
      @media (max-width: 600px) {
        padding: 8px;
      }
    }
    
    td {
      vertical-align: middle;
      padding: 12px 16px;
      
      @media (max-width: 600px) {
        padding: 8px;
      }
    }
  }
  
  .price-input {
    width: 160px;
    margin: 0;
    
    @media (max-width: 600px) {
      width: 100px;
    }
  }
  
  /* Mobile-specific adjustments */
  @media (max-width: 600px) {
    .v-card__title {
      padding: 12px 8px;
    }
    
    .v-card__text {
      padding: 12px 8px;
    }
    
    .v-card__subtitle {
      padding: 0 8px 12px;
    }
    
    .v-alert {
      padding: 8px;
    }
    
    .v-expansion-panel-content__wrap {
      padding: 8px;
    }
  }
}

.w-100 { width: 100% }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
