<template>
  <v-card
    class="settings-card"
    elevation="3"
    rounded="lg"
  >
    <v-card-title class="text-h5 font-weight-medium">
      <v-icon left color="primary">mdi-store</v-icon>
      Stores
    </v-card-title>
    
    <store-card 
      v-for="(store, index) in company.data_sources"
      :key="`store-${index}`"
      :store="store"
      :index="index"
    />
    
    <v-row class="ma-4 pa-4 text-center">
      <v-col>
        <v-btn
          @click="add_store"
          color="primary"
          rounded
          elevation="1"
          :loading="loading_add_store"
          v-if="is_fulfiller"
          :disabled="cant_add_stores"
        >
          <v-icon left>mdi-plus</v-icon>
          Add Store
        </v-btn>
        
        <v-alert
          type="warning"
          dense
          v-if="cant_add_stores"
          class="mt-4"
          elevation="2"
          border="left"
        >
          {{ this.get_plan && this.get_plan.id === 5 ? 
            'You need to add more 3PL Connects to be able to add more stores' : 
            'You are out of stores on your current plan.'}}
        </v-alert>
      </v-col>
    </v-row> 
    
    <v-dialog
      v-model="show_add_keys"
      max-width="500px"
      class="pa-4"
    >
      <v-card rounded="lg" elevation="4">
        <v-card-title class="primary white--text">
          <v-row dense>
            <v-col class="grow">Add Store</v-col>
            <v-col class="shrink">
              <v-btn
                icon
                @click="show_add_keys = false"
              >
                <v-icon color="white">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        
        <v-expansion-panels accordion expand-icon="mdi-chevron-down" class="expansion-panels">
          <v-expansion-panel>
            <v-expansion-panel-header class="subtitle-1">
              <div>
              Option 1: With your key 
              <ToolTip>
                On brand new installs of {{$appName}}, users will be prompted if they want to outsource to a 3PL Partner, or fulfill themselves. If they choose to outsource, they will be given the option to paste a key which will allow them to connect with you right away with just one click.
              </ToolTip>
            </div>
            </v-expansion-panel-header>
            
            <v-expansion-panel-content>
              <v-text-field
                rounded
                outlined
                label="Your 3PL ID"
                readonly
                :value="company.id"
                append-outer-icon="mdi-content-copy"
                @click:append-outer="copy(company.id)"
              >
              </v-text-field>
              
              <div style="display:flex; gap: 1em">
                <v-text-field
                  rounded
                  outlined
                  label="Your company name"
                  v-model="company.name"
                  hide-details
                  @change="$store.dispatch('warehouse/save_company', ['name'])"
                >
                </v-text-field>
                
                <ToolTip>
                  Your name will be presented for users when they request to connect with you as a 3PL Partner.
                </ToolTip>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          
          <v-divider class="my-2"></v-divider>
          
          <v-expansion-panel>
            <v-expansion-panel-header class="subtitle-1">
              <div>
                Option 2: By credentials
                <ToolTip>
                    Ask your client for their Store Id and Store Transfer Key, which they find in Settings > Stores
                </ToolTip>
              </div>
            </v-expansion-panel-header>
            
            <v-expansion-panel-content>
              <v-text-field
                rounded
                outlined
                v-model="new_store.id"
                label="Store id"
                placeholder="f7f8d9b088d"
              >
              </v-text-field>
              
              <v-text-field
                rounded
                outlined
                v-model="new_store.transfer_key"
                label="Store transfer key"
                placeholder="7f8d9b088dbf65d775faad868566f8f1"
              >
              </v-text-field>
              
              <v-btn
                @click="add_store"
                color="primary"
                rounded
                block
                class="mt-4"
                :loading="loading_add_store"
              >
                <v-icon left>mdi-plus</v-icon>
                Add Store
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ToolTip from '@/components/ToolTip.vue'
import StoreCard from './StoreCard.vue'

export default {
  components: {
    ToolTip,
    StoreCard,
  },
  data(){
    return {
      save_disabled: true,
      save_loading: false,
      show_add_keys: false,
      loading_add_store: false,
      new_store: {
        id: '',
        transfer_key: '',
      }
    }
  },
  computed: {
    cant_add_stores: function () {
      console.log('CAS', +this.get_plan?.max_connected_stores, +(this.get_plan?.id === 5 ? this.get_plan?.amount_logistics_connects : this.get_plan?.max_connected_stores), (this.company?.data_sources?.filter(ds => !ds.exclude_from_fulfillment)?.length || 100))
      return (this.get_plan?.id === 5 ? this.get_plan?.amount_logistics_connects : this.get_plan?.max_connected_stores || 0) <= +(this.company?.data_sources?.filter(ds => !ds.exclude_from_fulfillment)?.length)
    },
    ...mapState({
      company: state => state.warehouse.company
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      is_fulfiller: 'app/is_fulfiller'
    })
  },
  methods: {
    copy(text){
      navigator.clipboard.writeText(text)
    },
    is_owner(ds){
      return ds.parent_company_id === this.company.id
    },
    async add_store(){
      if(this.cant_add_stores ) {
        return this.$store.commit('app/SET_SNACK_BAR', `Your current plan doesn't allow more stores. Upgrade to add more!`)
      }
      if(!this.show_add_keys) return this.show_add_keys = true
      this.loading_add_store = true
      await this.$store.dispatch('warehouse/add_data_source', this.new_store)
      this.loading_add_store = false
      this.show_add_keys = false
    }
  },
  mounted(){
    console.log(this.get_plan)
  }
}
</script>

<style lang="scss" scoped>
.settings-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 0.8s ease-out;
  margin-bottom: 24px;
  
  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  // }
  
  .v-card__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }
  
  .v-card__text {
    padding-top: 16px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.expansion-panels {
  margin: 8px;
  
  .v-expansion-panel-header {
    padding: 12px 16px;
    position: relative;
    
    &::after {
      content: "";
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-color: rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
    }
    
    &__icon {
      color: var(--v-primary-base) !important;
      font-size: 20px !important;
      font-weight: bold;
      margin-left: 8px;
    }
  }
  
  .v-expansion-panel {
    margin-bottom: 8px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 8px !important;
    overflow: hidden;
    
    &::before {
      box-shadow: none !important;
    }
  }
}
</style>
